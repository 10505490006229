import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	Grid,
	Typography,
	Card,
	CardContent,
	makeStyles,
} from '@material-ui/core';
import PortableText from 'react-portable-text';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.workwaveBlue,
		padding: '0px',
		borderRadius: '20px',
		minHeight: '210px',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
		position: 'relative',
		zIndex: 1,
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
		transition: 'transform 0.25s',
	},
	title: {
		fontSize: '1.2rem',
		textAlign: 'center',
		fontWeight: 700,
		// [theme.breakpoints.down('xs')]: {
		// 	marginLeft: '1rem',
		// },
	},
	pos: {
		textAlign: 'center',
		fontSize: '1.1rem',
		lineHeight: '1.8rem',
		marginTop: '10px',
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.0rem',
		},
	},
}));

export const IntroCard = ({ card }) => {
	const classes = useStyles();
	return (
		<Grid
			item
			xs={12}
			md={12}
			lg={4}
			container
			direction='column'
			justifyContent='center'
			alignItems='center'>
			<Card className={classes.root} elevation={0}>
				<CardContent
					style={{
						borderTop: `16px solid ${card.accentColor.hexValue ?? '#002D5C'}`,
						minHeight: '210px',
						display: 'flex',
						flexDirection: 'column',
						padding: '14px',
						alignItems: 'space-evenly',
						justifyContent: 'space-between',
					}}>
					<Grid
						item
						container
						direction='row'
						justifyContent='center'
						alignItems='center'
            style={{ paddingTop: '1rem'}}>
						<Grid item xs={2}>
							<FontAwesomeIcon
								icon={['fad', card.icon]}
								style={{
									color: card.accentColor.hexValue ?? '#002D5C',
									height: '60px',
									width: '60px',
									border: `1px solid #E5EAF4`,
									borderRadius: '8px',
									background: '#E5EAF4',
									padding: '10px',
									textAlign: 'center',
									display: 'block',
									margin: '0 auto'
								}}
							/>
						</Grid>
					</Grid>
					<Grid
						container
						item
						direction='column'
						justifyContent='flex-start'
						alignItems='center'
						style={{ minHeight: '110px', marginTop: '10px' }}>
						<PortableText
							content={card._rawContent}
							serializers={{
								h4: ({ children }) => (
									<Grid item>
										<Typography variant='h6' className={classes.title}>
											{children}
										</Typography>
									</Grid>
								),
								normal: ({ children }) => (
									<Grid item>
										<Typography variant='body1' className={classes.pos}>
											{children}
										</Typography>
									</Grid>
								),
							}}
						/>
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	);
};
