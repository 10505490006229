import React from 'react';
import { Container, makeStyles, Grid } from '@material-ui/core';
import { Intro } from './Intro';
import { IntroCard } from './IntroCard';
import { CenterFeatures } from './CenterFeatures';
import { WaveUpSVG } from '../WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../WaveSVGs/WaveDownSVG';
import { AwardsCards } from './AwardsCard';

const useStyles = makeStyles((theme) => ({
  intro: {
    marginTop: '-5rem',
  },
  centerFeatures: {
    background: '#f5f9ff',
    padding: '18rem 0 20rem',
    [theme.breakpoints.down('sm')]: {
      padding: '10rem 0',
    },
  },
  awardsSection: {
    position: 'relative',
    marginTop: '32px',
    paddingBottom: '0px',
    display: 'flex',
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  whiteBox: {
    backgroundColor: '#ffffff',
    borderRadius: '20px',
    border: '1px solid #E5EAF4',
    boxShadow: '0px 10px 40px 0px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 1 calc(100% / 6 - 20px)',
    margin: '0 16px',
    overflow: 'hidden',
    '&:first-of-type': {
      marginLeft: 0,
    },
    '&:last-of-type': {
      marginRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 calc(100% / 3 - 40px)',
      marginBottom: '32px',
      '&:nth-of-type(1), &:nth-of-type(4)': {
        marginLeft: 0,
      },
      '&:nth-of-type(3), &:nth-of-type(6)': {
        marginRight: 0,
      },
    },
  },
}));

export const WhyBody = ({
  intro,
  introCards,
  bg,
  leftContent,
  ctaText,
  centerSectionCards,
  awardsArray = [], // Ensure default to avoid undefined errors
  awardsHeader,
}) => {
  const classes = useStyles();

  // Log awardsArray to check if it contains the expected data
  console.log('Awards Array in WhyBody:', awardsArray);

  return (
    <>
      <Container className={classes.intro}>
        <Intro intro={intro} />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ paddingTop: '2rem' }}
          spacing={4}
        >
          {introCards.map((card, index) => (
            <IntroCard card={card} key={index} />
          ))}
        </Grid>
      </Container>

      {/* Awards Section */}
      <Container className={classes.awardsSection}>
        <AwardsCards awardsArray={awardsArray} awardsHeader={awardsHeader} />
      </Container>

      <WaveDownSVG fill="#FFF" shadow />
      <div
        className={classes.centerFeatures}
        style={{
          backgroundImage: `url(${bg.asset.gatsbyImageData.images.fallback.src})`,
          backgroundSize: 'cover',
        }}
      >
        <CenterFeatures
          cards={centerSectionCards}
          leftContent={leftContent}
          ctaText={ctaText}
        />
      </div>
      <WaveUpSVG fill="#FFF" shadow />
    </>
  );
};
