import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, useMediaQuery, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  awardsSection: {},
  awardsContainer: {
    padding: '0 8px',
  },
  whiteBox: {
    backgroundColor: '#ffffff',
    borderRadius: '20px',
    border: '1px solid #E5EAF4',
    boxShadow: '0px 10px 40px 0px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 1 calc(100% / 6 - 27px)',
    margin: '0 16px',
    overflow: 'hidden',
    padding: '0px',
    maxWidth: '100%',
    '&:first-of-type': {
      marginLeft: 0,
    },
    '&:last-of-type': {
      marginRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 calc(100% / 3 - 40px)',
      marginBottom: '32px',
      '&:nth-of-type(1), &:nth-of-type(4)': {
        marginLeft: 0,
      },
      '&:nth-of-type(3), &:nth-of-type(6)': {
        marginRight: 0,
      },
    },
    [theme.breakpoints.down('xs')]: {
      flex: '1 1 calc(100% / 2 - 40px)',
      marginBottom: '32px',
      '&:nth-of-type(1), &:nth-of-type(3), &:nth-of-type(5)': {
        marginLeft: 0,
        marginRight: '16px',
      },
      '&:nth-of-type(2), &:nth-of-type(4), &:nth-of-type(6)': {
        marginRight: 0,
        marginLeft: '16px',
      },
    },
  },
}));

export const AwardsCards = ({ awardsArray = [], awardsHeader }) => {
  // Default value for awardsArray
  const classes = useStyles();
  const sm = useMediaQuery('(max-width: 600px)');
  console.log('Awards Array in AwardsCards:', awardsArray);

  return (
    <div className={classes.awardsSection}>
      {awardsHeader && (
        <Grid item xs={12}>
          <Typography
            variant="h2"
            color="primary"
            style={{
              fontWeight: 700,
              textAlign: 'center',
              marginBottom: '2rem',
            }}
          >
            {awardsHeader}
          </Typography>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        spacing={2}
        className={classes.awardsContainer}
      >
        {awardsArray.length > 0 ? ( // Check if awardsArray has items
          awardsArray.map((award, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              key={index}
              className={classes.whiteBox}
            >
              <GatsbyImage
                image={award.image?.asset.gatsbyImageData}
                alt={award.altText || 'Award Image'}
              />
            </Grid>
          ))
        ) : (
          <Typography
            variant="body1"
            style={{
              textAlign: 'center',
              width: '100%',
              marginTop: '1rem',
            }}
          >
            No awards available.
          </Typography>
        )}
      </Grid>
    </div>
  );
};
